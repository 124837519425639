export const contentTableConfig = {
  propList: [
    { prop: 'nick', label: '用户信息' },
    { prop: 'name', label: '姓名' },
    { prop: 'phone', label: '手机号' },
    { prop: 'company', label: '企业信息' },
    { prop: '', label: '订单量' },
    { prop: 'create_time', label: '注册时间' },
    { label: '来源', slotName: 'origin' },
    { label: '所属推广者', slotName: 'spreader' },
    { label: '操作', slotName: 'handler' }
  ]
}
